<script setup>
import { useI18n } from 'vue-i18n';
import { useHead } from "@unhead/vue";

const { t } = useI18n();

useHead( {
  title: t('home.meta.title'),
  meta: [
    { name: 'description', content: t('home.meta.description') }
  ]
})
</script>

<template>
  <div class="home">
    <section class="hero">
      <div class="container">
        <div class="content" data-aos="zoom-in" data-aos-duration="2000">
          <p><span>{{ t('home.hero.fast_secure') }}</span></p>
          <p>{{ t('home.hero.connecting_brazil') }}</p>
          <p><small>{{ t('home.hero.logistics_compliance') }}</small></p>
        </div>
      </div>
    </section>

    <section class="about">
      <div class="container">
        <div class="content">
          <div class="-image" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
            <img src="../assets/img/home/sobre-a-mypharma2go.png" alt="Sobre a MyPharma2GO">
          </div>
          <div class="-text">
            <h1>{{ t('home.about.title') }}</h1>
            <p>{{ t('home.about.description1') }}</p>
            <p>{{ t('home.about.description2') }}</p>
            <p>{{ t('home.about.description3') }}</p>
            <ul>
              <li>{{ t('home.about.list_item1') }}</li>
              <li>{{ t('home.about.list_item2') }}</li>
              <li>{{ t('home.about.list_item3') }}</li>
            </ul>
            <a :href="t('nav.links.about')" class="button">{{ t('home.about.learn_more') }}</a>
          </div>
        </div>
      </div>
    </section>

    <section class="public" id="public">
      <div class="container">
        <div class="intro">
          <h2>{{ t('home.public.title') }}</h2>
          <h3>{{ t('home.public.subtitle') }}</h3>
          <p>{{ t('home.public.description1') }}</p>
          <p><strong>{{ t('home.public.description2') }}</strong></p>
        </div>
        <div class="items">
          <div class="-item" data-aos="fade-in" data-aos-delay="50" data-aos-duration="1500">
            <img src="../assets/img/icon-industria-farmaceutica.svg" alt="Indústria Farmacêutica">
            <h4>{{ t('home.public.industry') }}</h4>
            <p>{{ t('home.public.industry_description') }}</p>
            <a :href="t('home.links.industry')" class="button-white">{{ t('home.public.learn_more') }}</a>
          </div>
          <div class="-item" data-aos="fade-in" data-aos-delay="100" data-aos-duration="1500">
            <img src="../assets/img/icon-operadoras-de-saude.svg" alt="Operadoras de Saúde e Autogestão">
            <h4>{{ t('home.public.health_operators') }}</h4>
            <p>{{ t('home.public.health_operators_description') }}</p>
            <a :href="t('home.links.health_operators')" class="button-white">{{ t('home.public.learn_more') }}</a>
          </div>
          <div class="-item" data-aos="fade-in" data-aos-delay="150" data-aos-duration="1500">
            <img src="../assets/img/icon-hospitais-publicos-e-privados.svg" alt="Hospitais Públicos e Privados">
            <h4>{{ t('home.public.hospitals') }}</h4>
            <p>{{ t('home.public.hospitals_description') }}</p>
            <a :href="t('home.links.hospitals')" class="button-white">{{ t('home.public.learn_more') }}</a>
          </div>
          <div class="-item" data-aos="fade-in" data-aos-delay="200" data-aos-duration="1500">
            <img src="../assets/img/icon-farmacias-e-drogarias.svg" alt="Farmácias e Drogarias">
            <h4>{{ t('home.public.pharmacies') }}</h4>
            <p>{{ t('home.public.pharmacies_description') }}</p>
            <a :href="t('home.links.pharmacies')" class="button-white">{{ t('home.public.learn_more') }}</a>
          </div>
          <div class="-item" data-aos="fade-in" data-aos-delay="250" data-aos-duration="1500">
            <img src="../assets/img/icon-associacoes-apoio-doencas-raras.svg" alt="Associações de Apoio a Pacientes e Doenças Raras">
            <h4>{{ t('home.public.associations') }}</h4>
            <p>{{ t('home.public.associations_description') }}</p>
            <a :href="t('home.links.associations')" class="button-white">{{ t('home.public.learn_more') }}</a>
          </div>
          <div class="-item" data-aos="fade-in" data-aos-delay="300" data-aos-duration="1500">
            <img src="../assets/img/icon-sistema-publico-de-saude.svg" alt="Sistema Público de Saúde">
            <h4>{{ t('home.public.public_health') }}</h4>
            <p>{{ t('home.public.public_health_description') }}</p>
            <a :href="t('home.links.public_health')" class="button-white">{{ t('home.public.learn_more') }}</a>
          </div>
          <div class="-item" data-aos="fade-in" data-aos-delay="350" data-aos-duration="1500">
            <img src="../assets/img/icon-prescritores.svg" alt="Prescritores">
            <h4>{{ t('home.public.prescribers') }}</h4>
            <p>{{ t('home.public.prescribers_description') }}</p>
            <a :href="t('home.links.prescribers')" class="button-white">{{ t('home.public.learn_more') }}</a>
          </div>
          <div class="-item" data-aos="fade-in" data-aos-delay="400" data-aos-duration="1500">
            <img src="../assets/img/icon-pacientes.svg" alt="Pacientes">
            <h4>{{ t('home.public.patients') }}</h4>
            <p>{{ t('home.public.patients_description') }}</p>
            <a :href="t('home.links.patients')" class="button-white">{{ t('home.public.learn_more') }}</a>
          </div>
        </div>
      </div>
    </section>

    <section class="profile">
      <div class="container">
        <a href="#public">
          <img src="../assets/img/icon-arrow-top.svg" alt="">
        </a>
        <p>{{ t('home.profile.description') }}</p>
      </div>
    </section>

    <section class="data">
      <div class="container">
        <div class="intro">
          <h2>{{ t('home.data.title') }}</h2>
          <p>{{ t('home.data.description') }}</p>
        </div>
        <div class="items">
          <div class="-item" data-aos="fade-down" data-aos-duration="2000" data-aos-delay="100">
            <p><span>{{ t('home.data.patients') }}</span> {{ t('home.data.patients_served') }}</p>
          </div>
          <div class="-item" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="200">
            <p>{{ t('home.data.skus') }} <span>{{ t('home.data.skus_quantity') }}</span> {{ t('home.data.skus_available') }}</p>
          </div>
          <div class="-item" data-aos="fade-down" data-aos-duration="2000" data-aos-delay="300">
            <p><span>{{ t('home.data.doctors') }}</span> {{ t('home.data.doctors_partners') }}</p>
          </div>
          <div class="-item" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="400">
            <p><span>{{ t('home.data.partnerships') }}</span> {{ t('home.data.partnerships_description') }}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
/* Adicione aqui os estilos CSS do seu componente */
</style>
