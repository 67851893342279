<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<template>
  <div>
    <footer>
      <div class="container">
        <div class="items">
          <div class="-item -mp2go">
            <p><small>{{ t('footer.priority') }}</small></p>
            <img src="@/assets/img/logo-mp2go.svg" alt="MP2GO">

            <ul>
              <li><a href="https://www.linkedin.com/company/mypharma2go/" target="_blank"><i class="fa-brands fa-linkedin"></i></a></li>
            </ul>
          </div>

          <div class="-item">
            <h4>{{ t('footer.attendance') }}</h4>

            <ul>
              <li><a href="https://api.whatsapp.com/send/?phone=5511998680834" target="_blank">+55 11 9 9868 0834</a></li>
              <li><a href="mailto:atendimento@mypharma2go.com">atendimento@mypharma2go.com</a></li>
            </ul>
          </div>

          <div class="-item">
            <h4>{{ t('footer.quick_access') }}</h4>

            <ul>
              <li>
                <a :href="t('nav.links.home')" class="active">{{ t('nav.home') }}</a>
              </li>
              <li>
                <a :href="t('nav.links.about')">{{ t('nav.about') }}</a>
              </li>
              <li>
                <a :href="t('nav.links.services')">{{ t('nav.services') }}</a>
              </li>
              <li>
                <a :href="t('nav.links.target_audience')">{{ t('nav.target_audience') }}</a>
              </li>
              <li>
                <a :href="t('nav.links.blog')">{{ t('nav.blog') }}</a>
              </li>
              <li>
                <a :href="t('nav.links.ecommerce')">{{ t('nav.ecommerce') }}</a>
              </li>
              <li>
                <a :href="t('nav.links.contact')">{{ t('nav.contact') }}</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="copy">
          <p>Copyright <i class="fa-regular fa-copyright"></i> 2025 <a :href="t('nav.links.home')">MyPharma2GO</a>. {{ t('footer.rights_reserved') }}</p>
        </div>
      </div>
    </footer>

    <div class="overlay"></div>
  </div>
</template>

<style scoped>
/* Adicione aqui os estilos CSS do seu componente */
</style>
