<script setup>
import { useI18n } from 'vue-i18n';
import { useHead } from "@unhead/vue";

const { t } = useI18n();

useHead( {
  title: t('associations.meta.title'),
  meta: [
    { name: 'description', content: t('associations.meta.description') }
  ]
})
</script>

<template>
  <div>
    <main>
      <section class="hero">
        <div class="container">
          <div class="content" data-aos="zoom-in" data-aos-duration="2000">
            <h1>{{ t('associations.hero.title') }} <span>{{ t('associations.hero.subtitle') }}</span></h1>
          </div>
        </div>
      </section>

      <section class="intro">
        <div class="container">
          <div class="content">
            <div class="-text">
              <h2>{{ t('associations.intro.title') }}</h2>
              <p>{{ t('associations.intro.description1') }}</p>
              <p>{{ t('associations.intro.description2') }}</p>
              <p>{{ t('associations.intro.description3') }}</p>
            </div>
            <div class="-image" style="background-image: url('../assets/img/public/associacoes-doencas-raras.jpg')" data-aos="flip-right" data-aos-easing="ease-out-cubic" data-aos-duration="2000"></div>
          </div>
        </div>
      </section>

      <section class="benefits">
        <div class="container">
          <div class="intro">
            <h2>{{ t('associations.benefits.title') }}</h2>
            <h3>{{ t('associations.benefits.subtitle') }}</h3>
          </div>
          <div class="items">
            <div class="-item" data-aos="fade-in" data-aos-delay="50" data-aos-duration="1500">
              <span>1</span>
              <h4>{{ t('associations.benefits.item1.title') }}</h4>
              <p>{{ t('associations.benefits.item1.description') }}</p>
            </div>
            <div class="-item" data-aos="fade-in" data-aos-delay="100" data-aos-duration="1500">
              <span>2</span>
              <h4>{{ t('associations.benefits.item2.title') }}</h4>
              <p>{{ t('associations.benefits.item2.description') }}</p>
            </div>
            <div class="-item" data-aos="fade-in" data-aos-delay="150" data-aos-duration="1500">
              <span>3</span>
              <h4>{{ t('associations.benefits.item3.title') }}</h4>
              <p>{{ t('associations.benefits.item3.description') }}</p>
            </div>
            <div class="-item" data-aos="fade-in" data-aos-delay="200" data-aos-duration="1500">
              <span>4</span>
              <h4>{{ t('associations.benefits.item4.title') }}</h4>
              <p>{{ t('associations.benefits.item4.description') }}</p>
            </div>
            <div class="-item" data-aos="fade-in" data-aos-delay="250" data-aos-duration="1500">
              <span>5</span>
              <h4>{{ t('associations.benefits.item5.title') }}</h4>
              <p>{{ t('associations.benefits.item5.description') }}</p>
            </div>
            <div class="-item" data-aos="fade-in" data-aos-delay="300" data-aos-duration="1500">
              <span>6</span>
              <h4>{{ t('associations.benefits.item6.title') }}</h4>
              <p>{{ t('associations.benefits.item6.description') }}</p>
            </div>
            <div class="-item" data-aos="fade-in" data-aos-delay="350" data-aos-duration="1500">
              <span>7</span>
              <h4>{{ t('associations.benefits.item7.title') }}</h4>
              <p>{{ t('associations.benefits.item7.description') }}</p>
            </div>
            <div class="-item" data-aos="fade-in" data-aos-delay="400" data-aos-duration="1500">
              <span>8</span>
              <h4>{{ t('associations.benefits.item8.title') }}</h4>
              <p>{{ t('associations.benefits.item8.description') }}</p>
            </div>
            <div class="-item" data-aos="fade-in" data-aos-delay="450" data-aos-duration="1500">
              <span>9</span>
              <h4>{{ t('associations.benefits.item9.title') }}</h4>
              <p>{{ t('associations.benefits.item9.description') }}</p>
            </div>
            <div class="-item" data-aos="fade-in" data-aos-delay="500" data-aos-duration="1500">
              <span>10</span>
              <h4>{{ t('associations.benefits.item10.title') }}</h4>
              <p>{{ t('associations.benefits.item10.description') }}</p>
            </div>
          </div>
        </div>
      </section>

      <section class="operation">
        <div class="container">
          <div class="intro">
            <h2>{{ t('associations.operation.title') }}</h2>
            <h3>{{ t('associations.operation.subtitle') }}</h3>
          </div>
          <div class="items">
            <div class="-item" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000" data-aos-easing="ease-in-sine">
              <div class="-img" style="background-image: url('../assets/img/public/associacoes-doencas-raras-identificacao-das-necessidades.jpg')"></div>
              <span>1</span>
              <div class="-text">
                <h4>{{ t('associations.operation.item1.title') }}</h4>
                <p>{{ t('associations.operation.item1.description') }}</p>
              </div>
            </div>
            <div class="-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000" data-aos-easing="ease-in-sine">
              <div class="-img" style="background-image: url('../assets/img/public/associacoes-doencas-raras-consolidacao-de-pedidos.jpg')"></div>
              <span>2</span>
              <div class="-text">
                <h4>{{ t('associations.operation.item2.title') }}</h4>
                <p>{{ t('associations.operation.item2.description') }}</p>
              </div>
            </div>
            <div class="-item" data-aos="fade-right" data-aos-delay="300" data-aos-duration="1000" data-aos-easing="ease-in-sine">
              <div class="-img" style="background-image: url('../assets/img/public/associacoes-doencas-raras-planejamento-logistico.jpg')"></div>
              <span>3</span>
              <div class="-text">
                <h4>{{ t('associations.operation.item3.title') }}</h4>
                <p>{{ t('associations.operation.item3.description') }}</p>
              </div>
            </div>
            <div class="-item" data-aos="fade-right" data-aos-delay="400" data-aos-duration="1000" data-aos-easing="ease-in-sine">
              <div class="-img" style="background-image: url('../assets/img/public/associacoes-doencas-raras-compliance-e-aprovacao.jpg')"></div>
              <span>4</span>
              <div class="-text">
                <h4>{{ t('associations.operation.item4.title') }}</h4>
                <p>{{ t('associations.operation.item4.description') }}</p>
              </div>
            </div>
            <div class="-item" data-aos="fade-right" data-aos-delay="500" data-aos-duration="1000" data-aos-easing="ease-in-sine">
              <div class="-img" style="background-image: url('../assets/img/public/associacoes-doencas-raras-entrega-final.jpg')"></div>
              <span>5</span>
              <div class="-text">
                <h4>{{ t('associations.operation.item5.title') }}</h4>
                <p>{{ t('associations.operation.item5.description') }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="suport">
        <div class="container">
          <div class="content">
            <div class="-intro">
              <h2>{{ t('associations.suport.title') }}</h2>
              <p>{{ t('associations.suport.description1') }}</p>
              <p><strong>{{ t('associations.suport.description2') }}</strong></p>
              <div class="-contact">
                <ul>
                  <li><a href="https://api.whatsapp.com/send/?phone=5511998680834" target="_blank">+55 11 9 9868 0834</a></li>
                  <li><a href="mailto:atendimento@mypharma2go.com">atendimento@mypharma2go.com</a></li>
                </ul>
              </div>
            </div>
            <div class="-faq">
              <h3>FAQ</h3>
              <div class="accordion">
                <div class="accordion__item" tabindex="1">
                  <div class="accordion__title">{{ t('associations.faq.question1') }}</div>
                  <div class="accordion__content">
                    <p>{{ t('associations.faq.answer1') }}</p>
                  </div>
                </div>
                <div class="accordion__item" tabindex="2">
                  <div class="accordion__title">{{ t('associations.faq.question2') }}</div>
                  <div class="accordion__content">
                    <p>{{ t('associations.faq.answer2') }}</p>
                  </div>
                </div>
                <div class="accordion__item" tabindex="3">
                  <div class="accordion__title">{{ t('associations.faq.question3') }}</div>
                  <div class="accordion__content">
                    <p>{{ t('associations.faq.answer3') }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="cta">
        <div class="container">
          <p>{{ t('associations.cta.description') }}</p>
          <a href="contato" class="button">{{ t('associations.cta.button') }}</a>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  name: "AppAssociations",
  data() {
    return {
      bodyClass: 'public-child',
    }
  },
  mounted() {
    document.body.classList.add(...this.bodyClass.split(' '))
  },
  beforeUnmount() {
    document.body.classList.remove(...this.bodyClass.split(' '))
  },
}
</script>

<style scoped>
/* Add necessary styles here */
</style>
