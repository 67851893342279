<script setup>
import { useI18n } from 'vue-i18n';
import { useHead } from "@unhead/vue";

const { t } = useI18n();

useHead( {
  title: t('ecommerce.meta.title'),
  meta: [
    { name: 'description', content: t('ecommerce.meta.description') }
  ]
})
</script>

<template>
  <div>
    <main>
      <section class="hero">
        <div class="container">
          <div class="content" data-aos="zoom-in" data-aos-duration="2000">
            <h1>{{ t('ecommerce.hero.title') }} <span>{{ t('ecommerce.hero.subtitle') }}</span></h1>
          </div>
        </div>
      </section>

      <section class="intro">
        <div class="container">
          <h2>{{ t('ecommerce.intro.title') }}</h2>
          <h3>{{ t('ecommerce.intro.subtitle') }}</h3>

          <div class="lojas">
            <div class="-item" v-for="(store, index) in stores" :key="index">
              <a :href="store.link" target="_blank">
                <div class="-img" :style="{ backgroundImage: `url(${store.image})` }"></div>
                <div class="-brand">
                  <img :src="store.logo" :alt="store.name">
                </div>
                <div class="-text">
                  <p>{{ t(store.description) }}</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section class="cta">
        <div class="container">
          <p>{{ t('ecommerce.cta.text') }}</p>
          <a href="https://api.whatsapp.com/send/?phone=5511998680834" target="_blank" class="button">
            {{ t('ecommerce.cta.button') }} <i class="fa-brands fa-whatsapp"></i>
          </a>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  name: "AppEcommerce",
  data() {
    return {
      bodyClass: 'ecommerce',
      stores: [
        {
          link: "https://store.mypharma2go.com/",
          image: "../assets/img/ecommerce/mp2go-bg.jpg",
          logo: "../assets/img/ecommerce/mp2go-logo.svg",
          name: "MyPharma2Go",
          description: "ecommerce.stores.mp2go"
        },
        {
          link: "https://mundihealth.com/",
          image: "../assets/img/ecommerce/mundihealth-bg.jpg",
          logo: "../assets/img/ecommerce/mundihealth-logo.svg",
          name: "MundiHealth",
          description: "ecommerce.stores.mundihealth"
        },
        {
          link: "https://mygut2go.com/",
          image: "../assets/img/ecommerce/mygut2go-bg.jpg",
          logo: "../assets/img/ecommerce/mygut2go-logo.svg",
          name: "MyGut2GO",
          description: "ecommerce.stores.mygut2go"
        },
        {
          link: "https://momandbaby2go.com/",
          image: "../assets/img/ecommerce/momandbaby-bg.jpg",
          logo: "../assets/img/ecommerce/momandbaby-logo.svg",
          name: "Mom&Baby2GO",
          description: "ecommerce.stores.momandbaby"
        },
        {
          link: "https://microbiomelabs.com.br/",
          image: "../assets/img/ecommerce/microbiome-labs-bg.jpg",
          logo: "../assets/img/ecommerce/microbiome-labs-logo.svg",
          name: "Microbiome Labs",
          description: "ecommerce.stores.microbiome"
        },
        {
          link: "https://garden.mundihealth.com/",
          image: "../assets/img/ecommerce/garden-of-life-bg.jpg",
          logo: "../assets/img/ecommerce/garden-of-life-logo.svg",
          name: "Garden Of Life",
          description: "ecommerce.stores.garden"
        },
        {
          link: "https://gerber.mundihealth.com/",
          image: "../assets/img/ecommerce/gerber-bg.jpg",
          logo: "../assets/img/ecommerce/gerber-logo.png",
          name: "Gerber",
          description: "ecommerce.stores.gerber"
        },
        {
          link: "https://saudecelular.com.br/",
          image: "../assets/img/ecommerce/truniagen-bg.jpeg",
          logo: "../assets/img/ecommerce/truniagen-logo.png",
          name: "Tru Niagen",
          description: "ecommerce.stores.truniagen"
        },
        {
          link: "https://pendulum.com.br/",
          image: "../assets/img/ecommerce/pendulum-bg.jpg",
          logo: "../assets/img/ecommerce/pendulum-logo.png",
          name: "Pendulum",
          description: "ecommerce.stores.pendulum"
        },
        {
          link: "https://azoproducts.com.br/",
          image: "../assets/img/ecommerce/azo-bg.jpg",
          logo: "../assets/img/ecommerce/azo-logo.svg",
          name: "AZO",
          description: "ecommerce.stores.azo"
        },
        {
          link: "https://santiveribrasil.com/",
          image: "../assets/img/ecommerce/santiveri-bg.jpg",
          logo: "../assets/img/ecommerce/santiveri-logo.svg",
          name: "Santiveri",
          description: "ecommerce.stores.santiveri"
        },
        {
          link: "https://neuroaidbr.com/",
          image: "../assets/img/ecommerce/neuroid-bg.jpg",
          logo: "../assets/img/ecommerce/neuroaid-logo.png",
          name: "NeuroAiD",
          description: "ecommerce.stores.neuroaid"
        }
      ]
    }
  },
  mounted() {
    document.body.classList.add(...this.bodyClass.split(' '))
  },
  beforeUnmount() {
    document.body.classList.remove(...this.bodyClass.split(' '))
  },
}
</script>

<style scoped>
/* Add necessary styles here */
</style>
