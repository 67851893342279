<script setup>
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const { t } = useI18n()
const route = useRoute()
</script>

<template>
  <div>
    <!-- loading -->
    <div class="se-pre-con"></div>

    <header>
      <div class="header">
        <div class="container">
          <div class="header-nav">
            <div class="logo">
              <a :href="t('nav.links.home')">
                <img src="@/assets/img/logo-mypharma2go.svg" alt="MyPharma2GO">
              </a>
            </div>

            <nav>
              <div>
                <span>menu</span> <i class="fa-solid fa-xmark"></i>
              </div>

              <ul class="menu">
                <li :class="{ active: route.path === t('nav.links.home') }">
                  <a :href="t('nav.links.home')">{{ t('nav.home') }}</a>
                </li>
                <li :class="{ active: route.path === t('nav.links.about') }">
                  <a :href="t('nav.links.about')">{{ t('nav.about') }}</a>
                </li>
                <li :class="{ active: route.path === t('nav.links.services') }">
                  <a :href="t('nav.links.services')">{{ t('nav.services') }}</a>
                </li>
                <li :class="{ active: route.path === t('nav.links.target_audience') }">
                  <a :href="t('nav.links.target_audience')">{{ t('nav.target_audience') }}</a>
                </li>
                <li :class="{ active: route.path === t('nav.links.blog') }">
                  <a :href="t('nav.links.blog')">{{ t('nav.blog') }}</a>
                </li>
                <li :class="{ active: route.path === t('nav.links.ecommerce') }">
                  <a :href="t('nav.links.ecommerce')">{{ t('nav.ecommerce') }}</a>
                </li>
                <li :class="{ active: route.path === t('nav.links.contact') }">
                  <a :href="t('nav.links.contact')">{{ t('nav.contact') }}</a>
                </li>
              </ul>
            </nav>

            <div class="toggle-nav">
              <i class="fa-solid fa-bars"></i>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<style scoped>
/* Add necessary styles here */
</style>
